<template>
    <b-card>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <b-form @submit.prevent="handleSubmit(onSubmit)">
                <div class="d-flex">
                    <feather-icon icon="ClipboardIcon" size="19"/>
                    <h4 class="mb-0 ml-50">Şikayet Konu Bilgileri</h4>
                </div>

                <b-row class="mt-1">

                    <b-col cols="12" md="12" lg="12">
                        <validation-provider #default="validationContext" name="İsim" rules="required">
                            <b-form-group label="İsim">
                                <b-form-input trim placeholder="İsim" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col cols="12" md="12" lg="12">
                        <validation-provider #default="validationContext" name="Dönüş Süresi" rules="required">
                            <b-form-group label="Dönüş Süresi">
                                <b-form-input trim placeholder="Dönüş Süresi" v-model="dataInfo.turnTimeValue" :state="getValidationState(validationContext)"/>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col cols="12" md="12" lg="12">
                        <validation-provider #default="validationContext" name="Dönüş Süresi Birimi" rules="required">
                            <b-form-group label="Dönüş Süresi Birimi" :state="getValidationState(validationContext)">
                                <v-select v-model="dataInfo.timeValueUnitId" :options="timeValueUnitOptions" :reduce="val => val.value" :clearable="true"/>
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col cols="12" md="12" lg="12">
                        <validation-provider #default="validationContext" name="e-Mail Adres">
                            <b-form-group label="e-Mail Adres" description="Bu konu ile ilgili bir şikayet kaydı oluştuğunda belirtilen adrese mail gönderimi yapılır.">
                                <b-form-input trim placeholder="e-Mail Adres" v-model="dataInfo.emailAddress" :state="getValidationState(validationContext)"/>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col cols="12" md="12" lg="12">
                        <validation-provider #default="validationContext" name="Durum" rules="required">
                            <b-form-group label="Durum" label-for="isActive" :state="getValidationState(validationContext)">
                                <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col cols="12" md="12" lg="12">
                        <validation-provider #default="validationContext" name="Erişim Bilgileri">
                            <b-form-group label="Erişim Bilgileri" description="Bu şikayet konusuna ait müşteri şikayet kayıtları ilgili erişim bilgisine sahip kullanıcılar tarafından görüntülenecektir." :state="getValidationState(validationContext)">
                                <v-select v-model="dataInfo.accessDefinitionList" :options="accessDefinitionOptions" :reduce="val => val.value" :clearable="true" multiple="multiple"/>
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                </b-row>

                <action-buttons :back-route="'definition-complaint-subject-list'"/>
            </b-form>
        </validation-observer>
        <Overlay :busy="busy"></Overlay>
    </b-card>
</template>

<script>
import {
    BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required, email} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/definition/complaint_subject/store"
import definitionModule from "@/views/system/definition/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {statusOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import {toastMessage} from "@core/utils/utils";
import accessModule from "@/views/definition/access/store"

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        BFormInvalidFeedback,

        ActionButtons,
        Overlay,
        vSelect,

        ValidationProvider,
        ValidationObserver,
    },
    props: {},
    data() {
        return {
            required,
            email,
        }
    },
    setup() {
        const STORE_MODULE_NAME = 'store'
        const STORE_DEFINITION_MODULE_NAME = 'store-definition'
        const STORE_ACCESS_MODULE_NAME = 'store-access'

        if (!store.hasModule(STORE_MODULE_NAME)) {
            store.registerModule(STORE_MODULE_NAME, storeModule)
            store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
            store.registerModule(STORE_ACCESS_MODULE_NAME, accessModule)
        }
        onUnmounted(() => {
            if (store.hasModule(STORE_MODULE_NAME)) {
                store.unregisterModule(STORE_MODULE_NAME)
                store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
                store.unregisterModule(STORE_ACCESS_MODULE_NAME)
            }
        })

        const toast = useToast()
        const busy = ref(false)
        const dataInfo = ref({
            id: 0,
            name: '',
            turnTimeValue: null,
            timeValueUnitId: null,
            emailAddress: null,
            isActive: true,
            accessDefinitionList: []
        })

        const timeValueUnitOptions = ref([])

        busy.value = true
        store.dispatch('store-definition/definitionList', {groupKey: 'TIME_UNIT'}).then(response => {
            response.data.data.forEach((value, index) => {
                timeValueUnitOptions.value.push({label: value.name, value: value.key})
                if (value.isDefault && router.currentRoute.params.id === undefined) {
                    dataInfo.value.expenseTypeId = value.key
                }
            });
        }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
            busy.value = false
        })

        if (router.currentRoute.params.id > 0) {
            busy.value = true
            store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
                dataInfo.value = response.data.data
            }).catch(error => {
                toastMessage(toast, 'danger', error.response.data.message)
                if (error.response.status === 403) {
                    router.push({name: 'definition-complaint-subject-list'})
                }
            }).finally(message => {
                busy.value = false
            })
        }

        const onSubmit = () => {
            busy.value = true
            store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
                toastMessage(toast, 'success', response.data.message)
                router.push({name: 'definition-complaint-subject-list'})
            }).catch(error => {
                toastMessage(toast, 'danger', error.response.data.message)
            }).finally(message => {
                busy.value = false
            })
        }

        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation()

        const accessDefinitionOptions = ref([])
        busy.value = true
        store.dispatch('store-access/fetchItems').then(response => {
            response.data.data.forEach((value, index) => {
                if (value.isActive) {
                    accessDefinitionOptions.value.push({label: value.name, value: value.id})
                }
            });
        }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
            busy.value = false
        })

        return {
            busy,
            dataInfo,
            refFormObserver,
            timeValueUnitOptions,
            accessDefinitionOptions,

            statusOptions,

            onSubmit,
            getValidationState,
            resetForm,
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
